<template>
  <div
    :class="{
      'block': true,
      'graph-pie': true,
      '-empty': !this.content.data
    }"
  >
    <div class="graph-pie-content">
      <h3>{{ content.title }}</h3>
      <canvas ref="ctx"></canvas>
      <p ref="percent" class="graph-pie-legend">
        <span v-if="percentage && percentageName" class="graph-pie-legend-percentage">
          {{ percentage }}
        </span>
        <span v-if="percentage && percentageName"  class="graph-pie-legend-percentageName">
          {{ percentageName }}
        </span>
      </p>
    </div>
    <p v-if="amount && amountPerHour" class="graph-pie-legendAmount">
      <span class="graph-pie-legendAmount-percentageName">
        per hour : {{ amountPerHour }} €
      </span>
      <span class="graph-pie-legendAmount-percentage">
        total : {{ amount }} €
      </span>

    </p>
  </div>
</template>

<script src="./script.js"></script>
<style src="./style.less" lang="less"></style>
