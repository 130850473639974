import ComponentMixin from '../../js/componentMixin.js'
import ViewMixin from '../../js/viewMixin'

export default {
  name: 'flight details',
  mixins: [
    ViewMixin,
    ComponentMixin
  ],
  props: {
    content: {
      type: Array
    }
  },
  data: () => {
    return {
      currency: '€'
    }
  },
  mounted () {
    this.revealButtons = this.$el.querySelectorAll('.flight-details-list-item-card')
    this.revealItem = this.$el.querySelectorAll('.flight-details-list-item-reveal')
    this.$nextTick(() => {
      this.getTableHeight()
    })
  },
  methods: {
    getTableHeight () {
      this.revealItem.forEach(el => {
        el.size = el.querySelector('.reveal-container').getBoundingClientRect().height
      })
    },
    toggleReveal (id) {
      if (this.revealItem[id].active) {
        this.revealItem[id].style.height = '0px'
        this.revealItem[id].active = false
        this.content[id].isActive = false
      } else {
        this.revealItem[id].style.height = this.revealItem[id].size + 'px'
        this.revealItem[id].active = true
        this.content[id].isActive = true
      }
    }
  }

}
