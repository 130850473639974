import moment from 'moment'

export default {
  namespaced: true,
  state: {
    from: moment().format('MMM YYYY'),
    to: moment().format('MMM YYYY')
  },
  getters: {
    from: (state) => state.from ? state.from : null,
    to: (state) => state.to ? state.to : null
  },
  actions: {
    setFromTo ({ commit }, { from, to } = {}) {
      if (from) {
        commit('setFrom', from)
      }
      if (to) {
        commit('setTo', to)
      }
    }
  },
  mutations: {
    setTo (state, to) {
      state.to = to
    },
    setFrom (state, from) {
      state.from = from
    }
  }
}
