import componentMixin from '../../js/componentMixin'
import Card from '../Card'
export default {
  name: 'account',
  props: {
    content: {}
  },
  components: {
    Card
  },
  mixins: [componentMixin],
  data: () => {
    return {
      cardData: {
        name: 'See details',
        link: this.content.url
      }
    }
  }
}
