import { createStore } from 'vuex'

import auth from './modules/auth'
import aircrafts from './modules/aircrafts'
import missions from './modules/missions'
import financialDetails from './modules/financial-details'
import reports from './modules/reports'
import changePassword from './modules/changePassword'
import datepicker from './modules/datepicker'
import loading from './modules/loading'

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    aircrafts,
    missions,
    financialDetails,
    reports,
    changePassword,
    datepicker,
    loading
  }
})
