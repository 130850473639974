export default {
  namespaced: true,
  state: {
    loading: false
  },
  getters: {
    isLoading: (state, getters, rootState, rootGetters) => {
      return state.loading
    }
  },
  actions: {
    setLoading ({ commit }, payload) {
      commit('setLoading', payload)
    }
  },
  mutations: {
    setLoading (state, payload) {
      state.loading = payload
    }
  }
}
