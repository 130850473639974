// import { Calendar, DatePicker } from 'v-calendar'
import moment from 'moment'
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
export default {
  name: 'calendar',
  props: {
    infos: Array
  },
  components: { VueCal },
  data () {
    return {
      selectedEvent: {},
      showDialog: false,
      showAllDayEvents: 0
    }
  },
  computed: {
    events () {
      const events = []
      this.infos.map(event => {
        event.legs.forEach(leg => {
          const date = new Date(leg.datetime_z)
          const formatedLeg = {
            start: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
            end: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
            title: `${leg.from.city} to ${leg.to.city}`,
            allDay: true,
            leg
          }
          events.push(formatedLeg)
        })
      })
      return events
    },
    date () {
      // console.log('data: -> ', this.selectedEvent.leg.date.split('T')[0])
      // console.log('moemnt: -> ', moment(this.selectedEvent.leg.date.split('T')[0]))
      return `${moment(this.selectedEvent.leg.datetime_z).format('dddd Do MMMM')} ‧ ${moment(this.selectedEvent.leg.datetime_z).format('h a')} to ${moment(this.selectedEvent.leg.datetime_z).add(this.selectedEvent.leg.flight_time.real || this.selectedEvent.leg.flight_time.sales || this.selectedEvent.leg.flight_time.leon || this.selectedEvent.leg.flight_time.avinode || 0, 'minutes').format('h a')}`
    },
    time () {
      // console.log('data: -> ', this.selectedEvent.leg.date.split('T')[0])
      // console.log('moemnt: -> ', moment(this.selectedEvent.leg.date.split('T')[0]))
      const minutes = this.selectedEvent.leg.flight_time.real || this.selectedEvent.leg.flight_time.sales || this.selectedEvent.leg.flight_time.leon || this.selectedEvent.leg.flight_time.avinode || 0
      return `${Math.floor(minutes / 60)} hour${minutes >= 60 ? 's' : ''} flight ‧ ${Math.round(Object.values(this.selectedEvent.leg.costs).reduce((acc, current) => acc + current, 0) * 100) / 100}€`
    }
  },
  methods: {
    onEventClick (event, e) {
      this.selectedEvent = event
      this.showDialog = true
      e.stopPropagation()
    },
    closeModal () {
      this.showDialog = false
    }
  }
}
