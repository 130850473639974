import Chart from 'chart.js'
import componentMixin from '../../js/componentMixin'

function getValueEuro (value, floor = true) {
  return value > 1000 ? floor ? Math.floor(value / 1000) + ' K€' : Math.floor(value) / 1000 + ' K€' : value + ' €'
}

const ID_TOOLTIP = 'chartjs-tooltip'
const strokeStyle = {
  backgroundColor: 'transparent',
  borderColor: '#fff',
  pointColor: 'transparent',
  pointBackgroundColor: 'transparent',
  pointBorderColor: 'transparent',
  borderWidth: 4,
  lineTension: 0.4
}

export default {
  name: 'card list',
  mixins: [componentMixin],
  props: {
    dataset: {},
    datas: {
      type: Object,
      required: true
    },
    datasDisplay: {
      type: Array,
      required: true
    }
  },
  data: () => {
    return {
      loaded: false
    }
  },
  watch: {
    dataset () {
      this.loaded ? this.changeData() : this.setLine()
      this.loaded = true
    },
    datasDisplay: {
      handler: function () {
        this.changeData()
      },
      deep: true
    }
  },
  computed: {
    datasComputed () {
      const returned = []

      this.datasDisplay.forEach((name) => {
        if (Object.hasOwnProperty.call(this.datas, name)) {
          const newObj = {
            data: this.datas[name].datasets,
            ...strokeStyle,
            ...this.datas[name].options
          }

          returned.push(newObj)
        }
      })
      return returned
    }
  },
  mounted () {
    if (Object.keys(this.dataset).length !== 0) {
      this.setLine()
    }
  },
  unmounted () {
    if (this.lineChart) this.lineChart.destroy()

    const tooltip = document.getElementById(ID_TOOLTIP)
    if (tooltip) tooltip.remove()
  },
  methods: {
    setLine () {
      const THAT = this
      // const tooltipModel = this.tooltipModel
      this.lineChart = new Chart(THAT.$refs.ctx.getContext('2d'), {
        type: 'line',
        data: {
          strokeColor: '#fff',
          datasets: this.datasComputed
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
            // Disable the on-canvas tooltip
            enabled: false,

            custom: function (tooltipModel) {
              // Tooltip Element
              var tooltipEl = document.getElementById(ID_TOOLTIP)

              // Create element on first render
              if (!tooltipEl) {
                tooltipEl = document.createElement('div')
                tooltipEl.id = ID_TOOLTIP
                tooltipEl.innerHTML = '<table></table>'
                document.body.appendChild(tooltipEl)
              }

              // Hide if no tooltip
              if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = 0
                return
              }

              // Set caret Position
              tooltipEl.classList.remove('above', 'below', 'no-transform')
              if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign)
              } else {
                tooltipEl.classList.add('no-transform')
              }

              function getBody (bodyItem) {
                return bodyItem.lines
              }

              // Set Text
              if (tooltipModel.body) {
                var titleLines = tooltipModel.title || []
                var bodyLines = tooltipModel.body.map(getBody)

                var innerHtml = '<thead>'

                titleLines.forEach(function (title) {
                  innerHtml += '<tr><th>' + title + '</th></tr>'
                })
                innerHtml += '</thead><tbody>'

                bodyLines.forEach(function (body, i) {
                  var colors = tooltipModel.labelColors[i]
                  var style = 'background:' + colors.backgroundColor
                  style += '; border-color:' + colors.borderColor
                  style += '; border-width: 2px'
                  var span = '<span style="' + style + '"></span>'
                  let text = body[0].split(':').reverse()
                  if (text[1].includes('€')) {
                    text = getValueEuro(parseInt(text[0]), false)
                  } else {
                    text.join('')
                  }
                  innerHtml += '<tr><td>' + span + text + '</td></tr>'
                })
                innerHtml += '</tbody>'

                var tableRoot = tooltipEl.querySelector('table')
                tableRoot.innerHTML = innerHtml
              }

              // `this` will be the overall tooltip
              var position = this._chart.canvas.getBoundingClientRect()

              // Display, position, and set styles for font
              tooltipEl.style.opacity = 1
              tooltipEl.style.position = 'absolute'
              tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px'
              tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px'
              tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily
              tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px'
              tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle
              tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px'
              tooltipEl.style.pointerEvents = 'none'
            }
          },
          layout: {
            padding: {
              left: 10,
              right: 10,
              top: 50,
              bottom: 0
            }
          },
          legend: {
            display: false
          },

          showLines: true,
          scales: {
            yAxes: [{
              // offset: 100,
              ticks: {
                beginAtZero: true,
                minTicksLimit: 5,
                maxTicksLimit: 5, // Include a dollar sign in the ticks
                callback: function (value, index, ticks) {
                  return getValueEuro(value)
                },
                fontColor: '#fff',
                fontSize: 15,
                fontStyle: 'bold',
                padding: 8
              },
              color: 'green',
              gridLines: {
                drawBorder: false,
                color: 'rgba(255, 255, 255, 0.3)'
              }
            }, {
              id: 'right-y-axis',
              position: 'right',
              textAlign: 'right',
              ticks: {
                beginAtZero: true,
                minTicksLimit: 5,
                maxTicksLimit: 5,
                // Include a dollar sign in the ticks
                callback: function (value, index, ticks) {
                  return value + 'h'
                },
                fontColor: '#fff',
                fontSize: 15,
                fontStyle: 'bold',
                padding: 8
              },
              color: 'green',
              gridLines: {
                drawBorder: false,
                color: 'rgba(255, 255, 255, 0.3)'
              }
            }],
            xAxes: [{
              gridLines: {
                display: false,
                color: 'rgba(255, 255, 255, 0.3)'
              },
              type: 'category',
              labels: this.dataset.labels,
              ticks: {
                fontColor: '#fff',
                fontSize: 11,
                fontStyle: 'bold',
                padding: 6
              }
            }]
          }
        }
      })
      let high = 0
      let highId = 0
      this.dataset.datasets.map((el, id) => {
        if (el > high) {
          high = el
          highId = id
        }
      })
      setTimeout(() => {
        this.setHover(highId)
      }, 700)
    },
    changeData () {
      document.getElementById(ID_TOOLTIP).style.opacity = 0
      this.lineChart.data.labels.pop()
      this.lineChart.data.datasets = this.datasComputed
      this.lineChart.update()
      // setTimeout(() => {
      //   this.setHover(0)
      // }, 700)
    },
    setHover (id) {
      var meta = this.lineChart.getDatasetMeta(0)
      var rect = this.lineChart.canvas.getBoundingClientRect()
      var point = meta.data[id].getCenterPoint()
      var evt = new MouseEvent('mousemove', {
        clientX: rect.left + point.x,
        clientY: rect.top + point.y
      })
      var node = this.lineChart.canvas
      node.dispatchEvent(evt)
      // setTimeout(() => {
      //   document.getElementById('chartjs-tooltip').style.opacity = 1
      // }, 500)
    }
  }
}
