import GraphLine from '../GraphLine'

const listGraph = {
  turnover: {
    title: 'Yearly turnover',
    options: {
      label: '€',
      borderColor: '#0D70F2'
    }
  },
  costs: {
    title: 'Yearly costs',
    options: {
      label: '€',
      borderColor: '#DB5461'
    }
  },
  hours: {
    title: 'Completed flight hours',
    options: {
      label: 'H',
      borderColor: '#559df9',
      backgroundColor: '#559df959',
      order: -1,
      type: 'bar',
      yAxisID: 'right-y-axis'
    }
  }
}

export default {
  name: 'overview',
  components: { GraphLine },
  props: {
    content: Array
  },
  data: () => {
    return {
      currentDataset: {},
      dataGraphs: {},
      graphDisplay: ['turnover', 'costs', 'hours']
    }
  },
  beforeMount () {
    this.currentDataset = this.content.chartData.turnover

    // set data
    for (const key in listGraph) {
      if (Object.hasOwnProperty.call(this.content.chartData, key)) {
        this.dataGraphs[key] = {
          ...listGraph[key],
          ...this.content.chartData[key],
          value: this.content[key]
        }
      }
    }
  },
  methods: {
    changeData (chartName, pos) {
      if (!this.graphDisplay.includes(chartName)) {
        this.graphDisplay.splice(pos, 0, chartName)
      } else if (this.graphDisplay.length > 1) {
        this.graphDisplay = this.graphDisplay.filter(e => e !== chartName)
      }
    }
  }
}
