import feathers from '../../feathers'

export default {
  namespaced: true,
  state: {
    user: undefined
  },
  actions: {
    async newPassword ({ commit }, { password, oldPassword } = {}) {
      if (password && oldPassword) {
        return feathers.client.service('authentication/changePassword').create({ password, oldPassword }).then(({ user }) => {
          commit('setUser', user)
        })
      }
    }
  },
  mutations: {
    setUser (state, user) {
      state.user = user
    }
  }
}
