import Overview from '../../components/Overview'
import Account from '../../components/Account'
import ViewMixin from '../../js/viewMixin'
import ComponentMixin from '../../js/componentMixin'
import GraphPie from '../../components/GraphPie'
import Calendar from '../../components/Calendar'
import MonthResult from '../../components/MonthResult'
import AccountStatus from '../../components/AccountStatus'

import { MoneyFormat } from '../../helpers/index'
import { mapGetters } from 'vuex'

// import { openPdf } from '../../helpers'
const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]
export default {
  name: 'briefing',
  components: {
    Overview,
    GraphPie,
    Account,
    Calendar,
    MonthResult,
    AccountStatus
  },
  mixins: [ViewMixin, ComponentMixin],
  data: () => {
    return {
      time: new Date('YYY'),
      account: [
        {
          name: 'Account Status',
          value: '-130000',
          depositInput: true,
          deposit: true,
          url: ''
        },
        {
          name: 'Last Statement of Invoice',
          value: '130000',
          depositInput: false,
          deposit: true,
          url: ''
        }
      ]
    }
  },
  methods: {
    getGraphData () {
      const year = '2021'
      let totalTurnover = 0
      let totalFlight = 0
      let totalcosts = 0
      const valuesTurnover = months.map((month, i) => {
        const _month = Object.keys(this.reportsByMonth[year]).find(
          e => i + 1 === parseInt(e)
        )
        if (_month) {
          const totalMonth = this.reportsByMonth[year][
            _month
          ].files[0].data.details.turnover.entries.find(
            el => el.name === 'TOTAL REVENUE'
          ).month
          totalTurnover += parseInt(totalMonth)
          return totalMonth
        } else {
          return 0
        }
      })
      const valuesFlight = months.map((month, i) => {
        const _month = Object.keys(this.reportsByMonth[year]).find(
          e => i + 1 === parseInt(e)
        )
        if (_month) {
          const totalMonth = this.reportsByMonth[year][_month].files[0].data
            .details.flightTime.total.month
          totalFlight += parseInt(totalMonth)
          return totalMonth
        } else {
          return 0
        }
      })
      const valuesCosts = months.map((month, i) => {
        const _month = Object.keys(this.reportsByMonth[year]).find(
          e => i + 1 === parseInt(e)
        )
        if (_month) {
          const totalMonth = this.reportsByMonth[year][_month].files[0].data
            .details.total.month
          totalcosts += parseInt(totalMonth)
          return totalMonth
        } else {
          return 0
        }
      })
      return {
        turnover: `${MoneyFormat(totalTurnover)}€`,
        hours: `${totalFlight}H`,
        costs: `${MoneyFormat(totalcosts)}€`,
        chartData: {
          turnover: {
            labels: [...months],
            datasets: valuesTurnover
          },
          hours: {
            labels: [...months],
            datasets: valuesFlight
          },
          costs: {
            labels: [...months],
            datasets: valuesCosts
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters('reports', {
      reportsByMonth: 'reportsByMonth',
      flightByMonth: 'flightByMonth'
    }),
    ...mapGetters('missions', {
      missions: 'missions'
    }),
    reports () {
      // const date = new Date()
      // if (this.reportsByMonth[date.getFullYear()] && this.reportsByMonth[date.getFullYear()][date.getMonth()]) {
      // const reports = this.reportsByMonth[date.getFullYear()][date.getMonth()].files.map(file => {
      const reports = this.reportsByMonth['2021']['02'].files.map(file => {
        return {
          type: 'flight',
          name: file.name,
          flight: this.reportsByMonth['2021']['02'].date.format('MMM YYYY'),
          pdf: file.pdf
        }
      })
      reports.push({
        type: 'button',
        name: 'detailed report',
        to: 'reports'
      })
      return reports
      // }
    },

    overview () {
      return this.getGraphData()
    }
  }
}
