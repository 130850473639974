import ReportAdditionalItem from '../../components/ReportAdditionalItem'
import ViewMixin from '../../js/viewMixin'

import { mapGetters } from 'vuex'

export default {
  name: 'reports',
  components: {
    ReportAdditionalItem
  },
  mixins: [ViewMixin],
  computed: {
    ...mapGetters('reports', {
      additionalReports: 'additionalReports'
    })
  }
}
