import moment from 'moment'
export default {
  name: 'componentMixin',
  components: {
  },
  data () {
    return {
      currency: '€',
      mediaPrefix: process.env.VUE_APP_MEDIA_PREFIX
    }
  },
  methods: {
    moment,
    intFormater (num) {
      return (num !== undefined ? Math.round(num * 100) / 100 : '').toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
    }
  }
}
