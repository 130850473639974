import { openPdf } from '../../helpers'

export default {
  name: 'CardPdf',
  props: {
    infos: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  methods: {
    openPdf
  }
}
