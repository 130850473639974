<template>
  <div class="view financial-view">
    <section class="financial-view-top">
      <h3>{{ $t('my-financial-details') }}</h3>
      <button class="financial-view-top-button" @click="printPage">{{ $t('print-my-financial-details') }}</button>
    </section>

    <!-- <FromTo onlyFrom/> -->
    <FromTo />

    <div class="table-view-financial"
      v-if="financialDetailsV2 && financialDetailsV2.hoursFlight && financialDetailsV2.turnover && financialDetailsV2.costs && financialDetailsV2.total">
      <FinancialTable :data="financialDetailsV2.hoursFlight" />
      <FinancialTable :data="financialDetailsV2.turnover" :isBlue="true" />
      <FinancialTable :data="financialDetailsV2.costs" />

      <div class="table-view-financial__total">
        <h2 class="total__name">{{ financialDetailsV2.total.name }}</h2>
        <p class="total__value">
          {{ financialDetailsV2.total.value }} €
        </p>
      </div>
    </div>
    <div v-else class="content-view-financialEmpty">{{ $t('the-data-is-empty-for-this-month-please-select-another-one')
      }}</div>

    <!-- <div
      :class="{
        'financial-view-modal': true,
        '-open': flightOpen
      }"
    >
      <FlightDetails :content="missions">{{ $t('my-flight-details') }}<button class="financial-view-modal-close" @click="closeFlightDetails">
          <span class="icon icon-cross" />
        </button>
      </FlightDetails>
      <div class="financial-view-modal-bkg" @click="closeFlightDetails" />
    </div> -->
  </div>
</template>

<script src="./script.js"></script>
<style src="./style.less" lang="less"></style>
<style src="./print.less" lang="less"></style>
