export default {
  "my-briefing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon briefing"])},
  "account-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["État du compte"])},
  "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépôt"])},
  "see-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les détails"])},
  "my-aircraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon Avion"])},
  "my-financial-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes détails financiers"])},
  "my-flights-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes détails de Vols"])},
  "my-reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes rapports"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
  "flight-calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendrier des Vols"])},
  "flight-crew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Équipage de Vol"])},
  "see-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir plus"])},
  "see-less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir moins"])},
  "financial-position-of-the-months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position Financière des Mois"])},
  "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à"])},
  "current-month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mois en cours"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "forgot-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié"])},
  "last-month-result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultat du mois dernier"])},
  "see-invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir la facture"])},
  "year-overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu de l'année"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "mission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mission"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itinéraire"])},
  "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps"])},
  "selling-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix de vente"])},
  "cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coût"])},
  "margin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marge"])},
  "leg-infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infos du segment"])},
  "fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carburant"])},
  "engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moteur"])},
  "route-taxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxes de route"])},
  "handling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistance au sol"])},
  "catering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catering"])},
  "crew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Équipage"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réf"])},
  "€": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€"])},
  "maps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartes"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mois"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Année"])},
  "operators-remark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarque de l'opérateur"])},
  "monthly-result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultat mensuel"])},
  "see-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir tout"])},
  "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapports"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intitulé"])},
  "my-flight-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes détails de vol"])},
  "reset-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le mot de passe"])},
  "my-aircraft-insurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon assurance avion"])},
  "active-programs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmes actifs"])},
  "print-my-financial-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimer mes détails financiers"])},
  "the-data-is-empty-for-this-month-please-select-another-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les données sont vides pour ce mois, veuillez en sélectionner un autre"])},
  "account-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres du compte"])},
  "first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
  "last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "public-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom public"])},
  "specifies-how-your-name-will-appear-in-the-account-section-and-in-notices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spécifie comment votre nom apparaîtra dans la section du compte et dans les notifications"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
  "changing-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement de mot de passe"])},
  "actual-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe actuel"])},
  "new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
  "confirm-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le mot de passe"])},
  "Number of flight hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'heures de vol"])},
  "Sales flight hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heures de vol commerciales"])},
  "Owner flight hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heures de vol propriétaire"])},
  "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "Turnover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiffre d'affaires"])},
  "Total vols Commercaux": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total vols commerciaux"])},
  "Comissions Skyfirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissions Skyfirst"])},
  "Total vente proprietaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total vente propriétaire"])},
  "Vols sous-affrétés": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vols sous-affrétés"])},
  "TOTAL REVENUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOTAL DES REVENUS"])},
  "VARIABLE COSTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COÛTS VARIABLES"])},
  "Fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carburant"])},
  "Handling & ground services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistance & services au sol"])},
  "Overflight  & Terminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survol & Terminal"])},
  "Ops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ops"])},
  "Catering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restauration"])},
  "Crew Travel & subsistence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voyage et subsistance de l'équipage"])},
  "Maintenance Engines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance des moteurs"])},
  "Aircraft cleaning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettoyage de l'avion"])},
  "Aircraft Communication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communication de l'avion"])},
  "TOTAL VARIABLE COSTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOTAL DES COÛTS VARIABLES"])},
  "FIXED COSTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COÛTS FIXES"])},
  "Maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance"])},
  "Aircraft Technical Documentation & program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentation technique & programme de l'avion"])},
  "Licences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licences"])},
  "Aircraft Insurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurance de l'avion"])},
  "Bank charges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais bancaires"])},
  "Miscellaneous charges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais divers"])},
  "TOTAL FIXED COSTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOTAL DES COÛTS FIXES"])},
  "CREW COSTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COÛTS DE L'ÉQUIPAGE"])},
  "Wages Pilots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salaires des pilotes"])},
  "Wages Flight Attendant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salaires des agents de bord"])},
  "Training Pilots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formation des pilotes"])},
  "Crew Visa & passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa et passeport de l'équipage"])},
  "Crew Communication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communication de l'équipage"])},
  "Crew uniforms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uniformes de l'équipage"])},
  "TOTAL CREW COSTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOTAL DES COÛTS DE L'ÉQUIPAGE"])},
  "SKYFIRST COSTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COÛTS SKYFIRST"])},
  "Monthly fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais mensuels"])},
  "Fees per flight hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais par heure de vol"])},
  "TOTAL SKYFIRST COSTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOTAL DES COÛTS SKYFIRST"])},
  "TOTAL COST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COÛT TOTAL"])},
  "Operation result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultat de l'opération"])},
  "January": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Janvier"])},
  "February": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Février"])},
  "March": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mars"])},
  "April": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avril"])},
  "May": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mai"])},
  "June": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juin"])},
  "July": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juillet"])},
  "August": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Août"])},
  "September": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Septembre"])},
  "October": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Octobre"])},
  "November": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novembre"])},
  "December": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décembre"])},
  "valid-until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valide jusqu'à"])},
  "confirm-change-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention, si vous changez ces infos, elles seront utilisées pour se connecter à la plateforme. Souhaitez-vous continuer ?"])},
  "update-data-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour des données réussie"])},
  "update-data-fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de la mise à jour des données"])},
  "password-not-match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mots de passe ne correspondent pas"])},
  "costs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coûts"])},
  "flight-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps de vol"])},
  "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger"])},
  "no-reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun rapport"])}
}