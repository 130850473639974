import CardFile from '../CardFile'
import CardRouter from '../CardRouter'
import CardPdf from '../CardPdf'
import { openPdf } from '../../helpers'

export default {
  name: 'card',
  components: {
    CardFile,
    CardPdf,
    CardRouter
  },
  props: {
    infos: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data: () => {
    return {
    }
  },
  methods: {
    openPdf
  }
}
