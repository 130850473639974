<template>

  <div class="block calendar">
    <h3>{{ $t('flight-calendar') }}</h3>
    <vue-cal
      :time-from="7 * 60"
      :disable-views="['years', 'year', 'month', 'day']"
      active-view="week"
      hide-view-selector
      :show-all-day-events="false"
      :events="events"
      selected-date="2022-5-3"
      v-model:active-view="week"
      :on-event-click="onEventClick"
      :time="false"
      small
    />
    <div
      :class="{
        calendar__popin: true,
        '-open': showDialog
      }"
    >
      <div class="popin__container">
        <div class="container__city" v-if="showDialog">{{ selectedEvent.leg.from?.city }} to {{ selectedEvent.leg.to?.city }}</div>
        <div class="container__date" v-if="showDialog">{{ date }}</div>
        <div class="container__time" v-if="showDialog">{{ time }}</div>

        <button @click="closeModal" class="container__close">
          <span class="icon icon-cross" />
        </button>
      </div>
      <div @click="closeModal" class="popin__background" />
    </div>
  </div>
</template>

<script src="./script.js"></script>
<style src="./style.less" lang="less"></style>
