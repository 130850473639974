<template>
  <div class="aircraft view">
    <Heading class="heading">{{ $t('my-aircraft') }}<br v-if="isMobile" />
      {{ currentAircraft.registration }}
    </Heading>
    <div class="content-view" v-if="!isMobile">
      <section>
        <Map class="map" />
        <SliderImages class="images" :images="photos" />
      </section>
      <section>
        <CardList class="insurance" :cards="insurances">{{ $t('my-aircraft-insurance') }}</CardList>
        <CardList class="programs" :cards="programs">{{ $t('active-programs') }}</CardList>
        <Crew class="crew" :crew="crewMembers" />
      </section>
    </div>
    <div class="content-view" v-else>
      <Map class="map" />
      <CardList class="insurance" :cards="insurances">{{ $t('my-aircraft-insurance') }}</CardList>
      <CardList class="programs" :cards="programs">{{ $t('active-programs') }}</CardList>
      <Crew class="crew" :crew="crewMembers" />
      <SliderImages class="images" :images="photos" />
    </div>
  </div>
</template>
<script src="./script.js"></script>
<style src="./style.less" lang="less" scoped></style>
