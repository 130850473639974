<template>
  <div class="report-additional-item">
    <div class="report-additional-item__col col-date">
      <h4>{{ $t('date') }}</h4>
      <p>
        {{ getMonthtext(content.month) }} {{ content.year }}
      </p>
    </div>
    <div class="report-additional-item__col">
      <h4>{{ $t('costs') }}</h4>
      <div class="report-item-result">{{ convertPrice(content.data.costs) }} €</div>
    </div>

    <div class="report-additional-item__col">
      <h4>{{ $t('flight-time') }}</h4>
      <div class="report-item-result">{{ content.data.flightTime }}</div>
    </div>

    <div class="report-additional-item__col">
      <h4>{{ $t('margin') }}</h4>
      <div class="report-item-result">{{ convertPrice(content.data.turnover) }} €</div>
    </div>

    <a class="report-additional-item__download card"
      @click="() => openPdf(content.pdfId, `${content.year}-${content.month}.pdf`)">
      <div class="container">
        <span class="icon icon-download"></span>
        <div class="content">
          <h4>{{ $t('download') }}</h4>
        </div>
      </div>
    </a>
  </div>
</template>

<script src="./script.js"></script>
<style src="./style.less" lang="less"></style>
