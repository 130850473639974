import feathers from '../../feathers'

export default {
  namespaced: true,
  state: {
    userId: undefined,
    accessToken: undefined,
    user: undefined
  },
  getters: {
    user: (state) => state.userId ? state.user : undefined
  },
  actions: {
    async login ({ commit, dispatch }, { email, password } = {}) {
      let auth
      if (email && password) {
        auth = {
          strategy: 'local',
          email,
          password
        }
      }
      return feathers.client.authenticate(auth)
        .then(({ user, accessToken }) => {
          commit('setAccessToken', accessToken)
          commit('setUserId', user._id)
          commit('setUser', user)
          return Promise.all([
            dispatch('aircrafts/find', null, { root: true }),
            dispatch('missions/find', null, { root: true }),
            dispatch('financialDetails/find', null, { root: true }),
            dispatch('reports/find', null, { root: true })
          ])
        })
    },
    async logout ({ commit }) {
      commit('setUserId', undefined)
      return feathers.client.logout()
    },
    async updateUser ({ commit }, { firstname, lastname, email } = {}) {
      if (firstname && lastname && email) {
        // return feathers.client.service('authentication/updateUser').create({ firstname, lastname, email }).then(({ user }) => {
        //   commit('setUser', user)
        // })
        return feathers.client.service('api/methods').get('users|updateUser', {
          query: {
            email,
            firstname,
            lastname
          }
        })
      }
    },
    async newPassword ({ commit }, { oldPassword, password } = {}) {
      if (oldPassword && password) {
        // console.log('new pass yesyes', oldPassword, password)
        // return feathers.client.service('authentication/updateUser').create({ firstname, lastname, email }).then(({ user }) => {
        //   commit('setUser', user)
        // })
        return feathers.client.service('api/methods').get('users|updatePassword', {
          query: {
            oldPassword,
            password
          }
        })
      }
    }
  },
  mutations: {
    setUserId (state, userId) {
      state.userId = userId
    },
    setUser (state, user) {
      state.user = user
    },
    setAccessToken (state, accessToken) {
      state.accessToken = accessToken
    }
  }
}
