import io from 'socket.io-client'
import feathers from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import auth from '@feathersjs/authentication-client'
import { getStorage } from '@/helpers'

const socket = io(process.env.VUE_APP_HEADLESS_URL)
export const client = feathers()

client.configure(socketio(socket, {
  timeout: 60000
}))
client.configure(auth({
  path: '/api/authentication',
  storage: getStorage()
}))

export default {
  client
}
