<template>
  <div class="block graph-line">
    <div class="chart-container">
      <canvas id="myChart" ref="ctx"></canvas>
    </div>
  </div>
</template>

<!-- Js a fumer si tu en as envie @valentin -->
<script src="./script.js"></script>
<style src="./style.less" lang="less"></style>
