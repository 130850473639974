<template>
  <div class="vue-monthly-picker">
    <div class="month-picker-wrapper" :class="{ 'active visible': showMenu }">
      <div class="month-year-label picker" type="text" autocomplete="off" tabindex="0">
        <div class="month-year-display" :disabled="disabled" :class="[inputClass]">
          <div class="display-text" :class="'display-text-' + alignment" :style="[{ 'text-align': alignment }]">
            {{ placeHolder }} <span class="icon icon-chevron"></span>
          </div>
          <span class="vmp-input-append" @click.stop.prevent="clearSelect" v-if="clearOption && value">
            <i class="vmp-clear-icon" />
          </span>
        </div>
      </div>
      <div class="text"></div>
      <div class="date-popover" :class="menuClass" :style="menuStyle" tabindex="-1" ref="popover">
        <div class="picker" style="text-align: center">
          <div class="flexbox">
            <span class="prev icon icon-chevron" @click="prevYear" :class="{ deactive: !canBack }"></span>
            <div>{{ year }}</div>
            <span class="next icon icon-chevron" @click="nextYear" :class="{ deactive: !canNext }"></span>
          </div>
          <div class="flexbox monthItem">
            <template v-for="(month, idx) in monthLabels">
              <div class="item active" :class="{ selected: isCurrentSelected(year, idx) }"
                :style="[{ 'background-color': getBackgroundColor(year, idx) }]" v-if="isActive(idx)"
                @click="selectMonth(idx)" :key="idx">
                {{ month }}
              </div>
              <div v-else :class="{ selected: isCurrentSelected(year, idx) }" :key="idx * 1000" class="item deactive">
                {{ month }}
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'vue-monthly-picker',
  props: {
    // value: {
    //   default: null
    // },
    disabled: {
      type: Boolean,
      default: false
    },
    inputClass: {
      default: 'input'
    },
    placeHolder: {
      type: String,
      default: ''
    },
    alignment: {
      type: String,
      default: 'left',
      validator: function (value) {
        // The value must match one of these strings
        return ['left', 'right', 'center'].indexOf(value) !== -1
      }
    },
    selectedBackgroundColor: {
      type: String,
      default: '#007bff'
    },
    min: {
      default: null
    },
    max: {
      default: moment()
    },
    dateFormat: {
      type: String,
      default: 'YYYY/MM'
    },
    clearOption: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      showMenu: false,
      year: moment().format('YYYY'),
      month: moment().format('MM'),
      currentVal: moment(),
      value: ''
    }
  },
  mounted () {
    this.init()
  },
  watch: {
    value: function (value) {
      this.setValue(value)
    }
  },
  computed: {
    monthLabels () {
      if (this.$i18n.locale === 'fr') {
        return [
          'jan',
          'fev',
          'mar',
          'avr',
          'mai',
          'juin',
          'juil',
          'aou',
          'sep',
          'oct',
          'nov',
          'dec'
        ]
      } else {
        return [
          'jan',
          'feb',
          'mar',
          'apr',
          'may',
          'jun',
          'jul',
          'aug',
          'sep',
          'oct',
          'nov',
          'dec'
        ]
      }
    },
    menuClass () {
      return {
        visible: this.showMenu
        // hidden: !this.showMenu
      }
    },
    menuStyle () {
      return {
        left:
          this.alignment === 'right'
            ? '100%'
            : this.alignment === 'center'
              ? '50%'
              : '',
        transform:
          this.alignment === 'right'
            ? 'translate(-100%,0)'
            : this.alignment === 'center'
              ? 'translate(-50%,0)'
              : ''
      }
    },
    displayText () {
      if (this.value) {
        let valueMoment = null
        if (typeof this.value === 'string') {
          valueMoment = moment(this.value)
        } else {
          valueMoment = this.value
        }
        if (valueMoment && valueMoment.isValid()) {
          return valueMoment.format(this.dateFormat)
        }
      } else {
        return this.placeHolder
      }
      return this.placeHolder
    },
    canBack () {
      if (!this.min) return true
      const currentVal = this.internalMomentValue.clone().startOf('year')
      return this.min.isBefore(currentVal)
    },
    canNext () {
      if (!this.max) return true
      const currentVal = this.internalMomentValue.clone().endOf('year')
      return currentVal.isBefore(this.max)
    },
    internalMomentValue () {
      const yrMonth = this.year + '/' + this.month
      return moment(yrMonth, 'YYYY/MM')
    }
  },
  methods: {
    init () {
      document.addEventListener(
        'click',
        e => {
          if (this.$el && !this.$el.contains(e.target)) {
            if (
              e.srcElement.classList[0] === 'fromTo-block-item' &&
              e.srcElement.classList[1] === this.type
            ) { return }
            this.closeMenu()
          }
        },
        false
      )
      this.setValue(this.value)
    },
    openMenu () {
      if (!this.disabled) {
        // this.showMenu = !this.showMenu
      }
    },
    closeMenu () {
      this.showMenu = false
    },
    prevYear () {
      if (!this.canBack) return
      const newYear = parseInt(this.year) - 1
      this.year = newYear.toString()
    },
    nextYear () {
      if (!this.canNext) return
      const newYear = parseInt(this.year) + 1
      this.year = newYear.toString()
    },
    selectMonth (idx) {
      this.month = (parseInt(idx) + 1).toString()
      this.selectPicker()
      this.closeMenu()
    },
    selectPicker () {
      this.$emit('input', this.internalMomentValue.clone())
      this.$emit('selected', this.internalMomentValue.clone())
      this.value = this.internalMomentValue.clone()
    },
    setValue (value) {
      if (typeof value === 'string') {
        value = moment(value)
      }
      if (value && value.isValid()) {
        this.month = value.format('MM')
        this.year = value.format('YYYY')
      }
    },
    isActive (idx) {
      const realMonth = idx + 1
      const yrMonth =
        this.year + '/' + (realMonth < 10 ? '0' + realMonth : realMonth)
      if (this.min && moment(yrMonth, 'YYYY/MM').isBefore(this.min)) {
        return false
      }
      if (this.max && moment(yrMonth, 'YYYY/MM').isAfter(this.max)) {
        return false
      }
      return true
    },
    isCurrentSelected (year, monthIdx) {
      if (!this.value) {
        return false
      }
      let checkValue = this.value
      if (typeof this.value === 'string') {
        checkValue = moment(this.value)
      }
      if (checkValue && checkValue.isValid()) {
        const currentMonth = checkValue.format('MM')
        const currentYear = checkValue.format('YYYY')
        return (
          Number(currentMonth) === Number(monthIdx + 1) &&
          Number(currentYear) === Number(year)
        )
      }
      return false
    },
    getBackgroundColor (year, monthIdx) {
      if (this.isCurrentSelected(year, monthIdx)) {
        return this.selectedBackgroundColor
      }
    },
    clearSelect () {
      this.$emit('input', null)
      this.$emit('selected', null)
    }
  }
}
</script>

<style lang="less">
@import "../../styles/_vars.less";
@import "../../styles/_mixins.less";
@lightgray: #d4d4d4;

.vue-monthly-picker {
  pointer-events: all;
  display: block;

  .picker {

    .next,
    .prev {
      &:hover {
        cursor: pointer;
      }
    }

    .monthItem {
      display: block !important;

      .item {
        border-radius: 10 / @px;

        &.active {
          &:hover {
            cursor: pointer;
            background-color: @color_shadowBlue;
          }
        }
      }
    }

    .flexbox {
      padding: 0px;
      display: flex;
      flex-wrap: wrap;

      div {
        flex-grow: 1;
        padding: 15px 0;
      }

      .item {
        flex: 1;
        flex-basis: 25%;
      }
    }
  }

  .placeholder {
    color: #8b8b8b;
  }

  .date-popover {
    overflow-x: hidden;
    overflow-y: hidden;
    display: block;
    outline: none;
    max-width: 350px;
    width: 100%;
    transition: opacity 0.1s ease, height 0.33s @ease-OutQuad;
    overflow: scroll;
    position: relative;
    margin: auto;
    z-index: 10;
    font-size: 1rem;
    font-weight: 200;
    height: 0 / @px;

    &.visible {
      height: 300 / @px;
    }

    .picker {
      .flexbox {
        max-width: 97%;
      }
    }
  }

  .month-picker-wrapper {
    position: relative;
    display: block;

    &.visible {
      .display-text {
        .icon {
          transform: rotate(180deg);
        }
      }
    }
  }

  .month-year-label {
    outline: none;
    display: block;
    pointer-events: all;

    .vmp-input-append {
      display: none;
    }

    &:hover .vmp-input-append {
      display: block;
    }
  }

  .text {
    position: relative;
    z-index: 2;
  }

  .month-year-display {
    &:hover {
      cursor: pointer;
    }
  }

  .next,
  .prev {
    width: 16%;
    position: relative;
    display: flex;
    align-items: center;

    // &:after {
    //   content: "";
    //   position: absolute;
    //   left: 50%;
    //   top: 50%;
    //   -webkit-transform: translateX(-50%) translateY(-50%);
    //   transform: translateX(-50%) translateY(-50%);
    //   border: 6px solid transparent;
    // }
  }

  .next {
    &:before {
      // border-left: 10px solid #000;
      // margin-left: 5px;
      transform: rotate(-90deg);
    }

    &.deactive {
      &:hover {
        cursor: default;
      }

      &:before {
        opacity: 0.5;
      }
    }
  }

  .prev {
    &:before {
      transform: rotate(90deg);
    }

    &.deactive {
      &:hover {
        cursor: default;
      }

      &:before {
        opacity: 0.5;
      }
    }
  }

  .input {
    -moz-appearance: none;
    -webkit-appearance: none;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 3px;
    box-shadow: none;
    display: inline-flex;
    font-size: 1rem;
    justify-content: flex-start;
    line-height: 1.5;
    // padding: 2px calc(.625em - 1px);
    position: relative;
    vertical-align: top;
    color: #363636;
    max-width: 100%;
    width: 100%;
  }

  .deactive {
    color: #999999;
  }

  .selected {
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    font-weight: bold;
  }

  .display-text {
    width: 100%;
    height: 100%;
    font-weight: @bold;
    color: @color_blue;
    font-size: 12 / @px;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon {
      margin-left: 10 / @px;
      color: @color_text;
      transform: rotate(0);
      transition: transform 0.33s @ease-OutQuad;
      display: inline-block;
    }
  }

  .display-text-right {
    margin-right: 20px;
  }

  .vmp-input-append {
    position: absolute;
    top: 0;
    right: 0;
    // width: 30px;
    height: 100%;
    padding: 6px;
  }

  .vmp-clear-icon {
    display: inline-block;
    width: 100%;
    height: 100%;
    font-style: normal;
    color: #555;
    text-align: center;
    cursor: pointer;

    &:before {
      display: inline-block;
      content: "\2716";
      vertical-align: middle;
    }
  }
}
</style>
