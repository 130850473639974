<template>
  <div :class="[ {'is-open' : isOpen} , 'financial-detail']">
    <div class="financial-detail-title" @click="isMobile ? toggle() : ''" >
      <h3>{{ statement.title }}</h3>
      <span v-if="isMobile" :class="[isOpen ? 'icon-minus' : 'icon-plus', 'icon']" ></span>
    </div>
    <ul class="financial-detail-list">
      <li :class="['financial-detail-list-item']">
        <ul ref="subList">
          <li v-for="(entry, key) in statement.entries" :key="key">
            <span>{{ entry.name }}</span>
            <span>{{ entry.amountDisplay }}</span>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script src="./script.js"></script>
<style src="./style.less" lang="less"></style>
