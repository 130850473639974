export default {
  "my-briefing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My briefing"])},
  "account-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account status"])},
  "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit"])},
  "see-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See details"])},
  "my-aircraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My aircraft"])},
  "my-financial-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My financial details"])},
  "my-flights-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My flights details"])},
  "my-reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My reports"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "flight-calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flight calendar"])},
  "flight-crew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flight crew"])},
  "see-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See more"])},
  "see-less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See less"])},
  "financial-position-of-the-months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial position of the months"])},
  "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
  "current-month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current month"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "forgot-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password"])},
  "last-month-result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last month result"])},
  "see-invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See invoice"])},
  "year-overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year overview"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "mission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mission"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Route"])},
  "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
  "selling-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selling price"])},
  "cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cost"])},
  "margin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Margin"])},
  "leg-infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leg infos"])},
  "fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuel"])},
  "engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engine"])},
  "route-taxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Route taxes"])},
  "handling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handling"])},
  "catering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catering"])},
  "crew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crew"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ref"])},
  "€": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€"])},
  "maps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maps"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
  "operators-remark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operators remark"])},
  "monthly-result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly result"])},
  "see-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See all"])},
  "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
  "my-flight-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Flight details"])},
  "reset-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
  "my-aircraft-insurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My aircraft insurance"])},
  "active-programs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active programs"])},
  "print-my-financial-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print my financial details"])},
  "the-data-is-empty-for-this-month-please-select-another-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The data is empty for this month, please select another one"])},
  "account-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account settings"])},
  "first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
  "last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "public-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public name"])},
  "specifies-how-your-name-will-appear-in-the-account-section-and-in-notices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specifies how your name will appear in the account section and in notices"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["save"])},
  "changing-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changing password"])},
  "actual-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actual password"])},
  "new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
  "confirm-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirm password"])},
  "valid-until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valide until"])},
  "confirm-change-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that if you change this information, it will be used to log in to the platform. Do you wish to continue ?"])},
  "update-data-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update data success"])},
  "update-data-fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update data fail"])},
  "password-not-match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password not match"])},
  "costs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costs"])},
  "flight-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flight time"])},
  "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
  "no-reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No reports"])}
}