import componentMixin from '../../js/componentMixin'
import FinancialDetail from '../FinancialDetail'
export default {
  name: 'financial list',
  components: {
    FinancialDetail
  },
  mixins: [componentMixin],
  props: {
    statements: {
      type: Array,
      default () {
        return []
      }
    },
    total: {
      type: String,
      default: 0
    }
  },
  data: () => {
    return {
    }
  }
}
