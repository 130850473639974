<template>
  <Loader :class="`${isLoading ? '' : 'is-hide'}`" />
  <AppHeader />
  <router-view />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AppHeader from './components/AppHeader'
import Loader from './components/Loader'

export default {
  name: 'App',
  components: {
    AppHeader,
    Loader
  },
  computed: {
    ...mapGetters('loading', {
      isLoading: 'isLoading'
    })
  },
  methods: {
    ...mapActions('loading', {
      setLoading: 'setLoading'
    })
  },
  mounted () {
    // set lang from localStorage
    const locale = localStorage.getItem('locale')
    if (locale) {
      this.$i18n.locale = locale
    }
  }
}
</script>
<style lang="less">
@import "./styles/main.less";
</style>
