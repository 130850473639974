import Card from '../Card'

export default {
  name: 'card list',
  props: {
    cards: Array
  },
  components: {
    Card
  }
}
