import Heading from '../components/Heading'
import CardList from '../components/CardList'
import Login from '../components/Login'

export default {
  name: 'viewMixin',
  components: {
    Heading,
    CardList,
    Login
  },
  data () {
    return {
      isMobile: true
    }
  },
  mounted () {
    this.isMobile = window.innerWidth < 813
    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth < 813
    })
  }
}
