export default {
  name: 'CardRouter',
  props: {
    infos: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data: () => {
    return {
    }
  }
}
