export default {
  name: 'Crew',
  props: {
    crew: Array
  },
  data () {
    return {
      seeMore: false
    }
  },
  computed: {
    mediaPrefix () {
      return process.env.VUE_APP_MEDIA_PREFIX
    }
  },
  methods: {
    toggleSeeMore () {
      this.seeMore = !this.seeMore
    }
  }
}
