import ViewMixin from '../../js/viewMixin'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'settings',
  mixins: [ViewMixin],
  data () {
    return {
      password: '',
      oldPassword: '',
      passwordConfirm: '',
      firstname: '',
      lastname: '',
      email: '',
      responseParams: null,
      responsePassword: null
    }
  },
  computed: {
    ...mapGetters('auth', {
      user: 'user'
    })
  },
  methods: {
    ...mapActions('auth', {
      newPassword: 'newPassword'
    }),
    ...mapActions('auth', {
      updateUser: 'updateUser'
    }),
    async handleLogin () {
      if (this.password !== this.passwordConfirm) {
        this.responsePassword = this.$t('password-not-match')
        return
      }

      const resp = await this.newPassword({
        password: this.password,
        oldPassword: this.oldPassword
      })

      if (resp) {
        this.responsePassword = this.$t('update-data-success')
      } else {
        this.responsePassword = this.$t('update-data-fail')
      }
    },
    async handleChangeUser () {
      const confirm = window.confirm(this.$t('confirm-change-user'))
      if (!confirm) return

      const resp = await this.updateUser({
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email
      })

      if (resp) {
        this.responseParams = this.$t('update-data-success')
      } else {
        this.responseParams = this.$t('update-data-fail')
      }
    }
  },
  beforeMount () {
    this.firstname = this.user.firstname
    this.lastname = this.user.lastname
    this.email = this.user.email
  }
}
