<template>
  <div class="login login-block">
    <form action="">
      <img :src="logo" alt="" />
      <div class="input-block">
        <label for="login">{{ $t('login') }}</label>
        <input type="text" id="login" v-model="email" @keyup.enter="handleLogin" />
      </div>
      <div class="input-block">
        <label for="pwd">{{ $t('password') }}</label>
        <span :class="{ seePassword: true, '-see': seePassword }" @click="toggleSeePassword" />
        <input id="pwd" :type="seePassword ? 'text' : 'password'" v-model="password" @keyup.enter="handleLogin" />
      </div>
      <a href="">{{ $t('reset-password') }}</a>
      <div class="submit-container">
        <button type="button" @click="handleLogin">{{ $t('login') }}</button>
      </div>
    </form>
  </div>
</template>
<style src="./style.less" lang="less"></style>
<script>
import { mapGetters, mapActions } from 'vuex'
import Logo from '../../assets/skyfirst-logo.png'

export default {
  name: 'login',
  data () {
    return {
      email: '',
      password: '',
      seePassword: false,
      logo: Logo
    }
  },
  computed: {
    ...mapGetters('auth', {
      user: 'user'
    })
  },
  methods: {
    ...mapActions('auth', {
      login: 'login'
    }),
    ...mapActions('loading', {
      setLoading: 'setLoading'
    }),
    handleLogin () {
      this.setLoading(true)

      this.login({
        email: this.email,
        password: this.password
      }).then(() => {
        this.redirect()
        this.setLoading(false)
      })
    },
    update (values) {
      this.email = values.login
      this.password = values.password
    },
    redirect () {
      if (this.user) {
        if (this.$route.query.redirect) {
          this.$router.push(this.$route.query.redirect)
        } else {
          this.$router.push({ name: 'Aircraft' })
        }
      }
    },
    toggleSeePassword () {
      this.seePassword = !this.seePassword
    }
  }
}
</script>
