import feathers from '../../feathers'

export default {
  namespaced: true,
  state: {
    fetched: false,
    financialDetails: {}
  },
  getters: {
    financialDetailsByMonth: (state, getters, rootState, rootGetters) => {
      if (!rootGetters['aircrafts/currentAircraft']) return {}

      const financialDetails = (state.financialDetails.financialDetails || []).filter(financialDetail => financialDetail.aircraft === rootGetters['aircrafts/currentAircraft']._id)

      const financialDetailsByMonth = {}
      financialDetails.forEach(financialDetail => {
        financialDetailsByMonth[`${financialDetail.year}${financialDetail.month.toString().padStart(2, '0')}`] = financialDetail
      })

      return financialDetailsByMonth
    },
    financialDetailFromTo: (state, getters, rootState, rootGetters) => {
      if (!rootGetters['aircrafts/currentAircraft']) return {}

      const financialDetails = (state.financialDetails.financialDetails || []).filter(financialDetail => financialDetail.aircraft === rootGetters['aircrafts/currentAircraft']._id)

      const financialDetailsByMonth = {}
      financialDetails.forEach(financialDetail => {
        financialDetailsByMonth[`${financialDetail.year}${financialDetail.month.toString().padStart(2, '0')}`] = financialDetail
      })

      return financialDetailsByMonth
    }
  },
  actions: {
    async find ({ state, commit }) {
      if (!state.fetched) {
        const financialDetails = await feathers.client.service('api/my-plane/financial-details').find()
        commit('setFinancialDetails', financialDetails)
      }
    }
  },
  mutations: {
    setFinancialDetails (state, financialDetails) {
      state.fetched = true
      state.financialDetails = financialDetails
    }
  }
}
