<template>
    <div class="view reports">
        <Heading>{{ $t('my-reports') }}</Heading>
        <ul v-if="additionalReports && additionalReports.length">
            <li v-for="(item, key) in additionalReports" :key="key">
                <ReportAdditionalItem :content="item" />
            </li>
        </ul>
        <p v-else>
            {{ $t('no-reports') }}
        </p>
    </div>
</template>

<script src="./script.js"></script>
<!-- <style src="./style.less" lang="less"></style> -->
