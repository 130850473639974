<template>
  <div class="block flight-details">
    <h3>
      <slot></slot>
    </h3>

    <ul class="flight-details-list">
      <li class="flight-details-list-item" v-for="(mission, id) in content" :key="id">
        <div :class="[mission.isActive ? 'active' : '', 'flight-details-list-item-card', { unseen: mission.unseen }]"
          @click="toggleReveal(id)">
          <div v-if="isMobile" class="mobile-wrapper">
            <div class="flight-details-list-item-card-type">
              <h4>{{ $t('type') }}</h4>
              <div>{{ mission.type }}</div>
            </div>
            <div class="flight-details-list-item-card-mission">
              <h4>{{ $t('mission') }}</h4>
              <div>{{ mission.quote_id }}</div>
            </div>
          </div>
          <div v-if="!isMobile" class="flight-details-list-item-card-type">
            <h4>{{ $t('type') }}</h4>
            <div>{{ mission.type }}</div>
          </div>
          <div v-if="!isMobile" class="flight-details-list-item-card-mission">
            <h4>{{ $t('mission') }}</h4>
            <div>{{ mission.quote_id }}</div>
          </div>
          <div class="flight-details-list-item-card-date">
            <h4>{{ $t('date') }}</h4>
            <div>{{ moment(mission.date_start).format('DD/MM/YYYY') }} - {{
              moment(mission.date_end).format('DD/MM/YYYY') }}</div>
          </div>
          <div class="flight-details-list-item-card-route">
            <h4>{{ $t('route') }}</h4>
            <div>
              <span class="icon icon-marker"></span>
              <div>{{ mission.road }}</div>
            </div>
          </div>
          <div class="flight-details-list-item-card-time">
            <h4>{{ $t('time') }}</h4>
            <div>{{ mission.flightTimeFormatted }}</div>
          </div>
          <div class="flight-details-list-item-card-price">
            <h4>{{ $t('selling-price') }}</h4>
            <div>{{ intFormater(Math.round(mission.saved_data.price)) }} {{ currency }}</div>
          </div>
          <div class="flight-details-list-item-card-cost">
            <h4>{{ $t('cost') }}</h4>
            <div>{{ intFormater(Math.round(mission.saved_data.costs)) }} {{ currency }}</div>
          </div>
          <div class="flight-details-list-item-card-margin">
            <h4>{{ $t('margin') }}</h4>
            <div>{{ intFormater(Math.round(mission.saved_data.owner_margin)) }} {{ currency }}</div>
          </div>
        </div>
        <div class="flight-details-list-item-reveal" ref="reveal">
          <div class="reveal-container">
            <table class="table-leg" v-if="!isMobile">
              <thead>
                <th>{{ $t('leg-infos') }}</th>
                <th>{{ $t('fuel') }}</th>
                <th>{{ $t('engine') }}</th>
                <th>{{ $t('route-taxes') }}</th>
                <th>{{ $t('handling') }}</th>
                <th>{{ $t('catering') }}</th>
                <th>{{ $t('crew') }}</th>
              </thead>
              <tbody>
                <tr v-for="(leg, index) in mission.legs" :key="index">
                  <td>
                    <div class="first-line">
                      <span class="from">{{ leg.from.code }}</span>
                      <span class="icon icon-arrow-thin"></span>
                      <span class="to">{{ leg.to.code }}</span>
                      <span class="date">
                        {{ moment(leg.date).format('DD/MM/YYYY') }}</span>
                    </div>
                    <div class="second-line">
                      <span class="price">{{ intFormater(leg.price) }}</span>
                      <span class="people">
                        <span class="icon icon-people"></span>
                        {{ leg.pax }} PAX
                      </span>
                      <span class="time">
                        <span class="icon icon-clock"></span>{{ leg.time }}
                      </span>
                    </div>
                  </td>
                  <td>
                    <span class="fuel"> {{ intFormater(leg.costs.fuel) }} {{ currency }}</span>
                  </td>
                  <td>
                    <span class="engine"> {{ intFormater(leg.costs.engine) }} {{ currency }}</span>
                  </td>
                  <td>
                    <span class="taxes"> {{ intFormater(leg.costs.routes) }} {{ currency }}</span>
                  </td>
                  <td>
                    <span class="handling">
                      {{ intFormater(leg.costs.handling) }} {{ currency }}
                    </span>
                  </td>
                  <td>
                    <span class="catering">
                      {{ intFormater(leg.costs.catering) }} {{ currency }}
                    </span>
                  </td>
                  <td>
                    <span class="crew"> {{ intFormater(leg.costs.overnight) }} {{ currency }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-if="isMobile">
              <ul>
                <li v-for="(leg, index) in mission.legs" :key="index">
                  <div>
                    <div class="first-line">
                      <span class="from">{{ leg.from.code }}</span>
                      <span class="icon icon-arrow-thin"></span>
                      <span class="to">{{ leg.to.code }}</span>
                      <span class="date">{{ leg.date }}</span>
                    </div>
                    <div class="second-line">
                      <span class="price">{{ intFormater(leg.price) }}</span>
                      <span class="people"><span class="icon icon-people"></span>{{ leg.pax }} PAX</span>
                      <span class="time"><span class="icon icon-time"></span>{{ leg.time }}</span>
                    </div>
                  </div>
                  <div>
                    <h4>{{ $t('fuel') }}</h4>
                    <span class="fuel"> {{ intFormater(leg.costs.fuel) }} {{ currency }}</span>
                  </div>
                  <div>
                    <h4>{{ $t('engine') }}</h4>
                    <span class="engine"> {{ intFormater(leg.costs.engine) }} {{ currency }}</span>
                  </div>
                  <div>
                    <h4>{{ $t('route-taxes') }}</h4>
                    <span class="taxes"> {{ intFormater(leg.costs.routes) }} {{ currency }}</span>
                  </div>
                  <div>
                    <h4>{{ $t('handling') }}</h4>
                    <span class="handling">
                      {{ intFormater(leg.costs.handling) }} {{ currency }}
                    </span>
                  </div>
                  <div>
                    <h4>{{ $t('catering') }}</h4>
                    <span class="catering">
                      {{ intFormater(leg.costs.catering) }} {{ currency }}
                    </span>
                  </div>
                  <div>
                    <h4>{{ $t('crew') }}</h4>
                    <span class="crew"> {{ intFormater(leg.costs.overnight) }} {{ currency }}</span>
                  </div>
                </li>
              </ul>
            </div>

            <table v-if="'entries' in mission && mission.entries.length > 0" class="kef-entries">
              <thead>
                <th>{{ $t('description') }}</th>
                <th>{{ $t('ref') }}</th>
                <th>€</th>
              </thead>
              <tbody>
                <tr v-for="(kef, index) in mission.entries" :key="index">
                  <td>{{ kef.descr }}</td>
                  <td>{{ kef.reference }}</td>
                  <td>{{ kef.debits }} €</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script src="./script.js"></script>
<style src="./style.less" lang="less"></style>
