import moment from 'moment'

import SliderImages from '../../components/SliderImages'
import Map from '../../components/Map'
import CardList from '../../components/CardList'
import Crew from '../../components/Crew'
// import Calendar from '../../components/Calendar'

import ViewMixin from '../../js/viewMixin'

import { mapGetters } from 'vuex'

export default {
  name: 'aircraft',
  components: {
    SliderImages,
    Map,
    CardList,
    Crew
    // Calendar
  },
  mixins: [ViewMixin],
  computed: {
    ...mapGetters('aircrafts', {
      currentAircraft: 'currentAircraft'
    }),
    photos () {
      return this.currentAircraft.photo
    },
    crewMembers () {
      return this.currentAircraft.crewMembers
    },
    insurances () {
      return (this.currentAircraft.insurances ? Object.values(this.currentAircraft.insurances) : []).map(insurance => {
        // console.log('insurance', insurance)
        return {
          type: 'dated',
          name: insurance.name,
          date: moment(insurance.valid_until) ? moment(insurance.valid_until).format('MMMM YYYY') : null,
          file: `${process.env.VUE_APP_MEDIA_PREFIX}${insurance.pdf}`,
          pdf: insurance.pdf,
          unseen: false
        }
      })
    },
    programs () {
      return (this.currentAircraft.programs ? Object.values(this.currentAircraft.programs) : []).map(program => {
        // console.log('program', program)
        return {
          type: 'dated',
          name: program.name,
          date: program.valid_until ? moment(program.valid_until).format('MMMM YYYY') : null,
          file: `${process.env.VUE_APP_MEDIA_PREFIX}${program.pdf}`,
          pdf: program.pdf,
          unseen: false
        }
      })
    }
  }
}
