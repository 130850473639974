import Chart from 'chart.js'

export default {
  name: 'graphPie',
  props: {
    content: {
      type: Object
    }
  },
  watch: {
    content () {
      this.setNewData()
    }
  },
  data () {
    return {
      percentage: null,
      percentageName: null,
      percentageTotal: null,
      amount: null,
      amountPerHour: null
    }
  },
  computed: {
    datas () {
      if ('data' in this.content && this.content.data) {
        const datas = {
          labels: this.content.data.map((e) => e.label),
          datasets: [
            {
              label: 'Dataset 1',
              data: this.content.data.map((e) => e.value),
              backgroundColor: 'rgba(255, 255, 255, 0.3)',
              borderColor: '#06172b',
              borderWidth: 3,
              hoverBackgroundColor: '#559df9'
            }
          ]
        }

        return datas
      } else {
        return []
      }
    }
  },
  mounted () {
    this.initPie()
  },
  unmounted () {
    if (this.pie) this.pie.destroy()
  },
  methods: {
    initPie () {
      this.pie = new Chart(this.$refs.ctx.getContext('2d'), {
        type: 'pie',
        data: this.datas,
        labels: ['aa'],
        options: {
          cutoutPercentage: 50,
          position: 'nearest',

          responsive: true,
          legend: {
            display: false
          },

          // tooltip
          tooltips: {
            // Disable the on-canvas tooltip
            enabled: false,

            custom: (tooltipModel) => {
              function getBody (bodyItem) {
                return bodyItem.lines
              }

              var bodyLines = tooltipModel.body.map(getBody)

              bodyLines.forEach((body) => {
                const splitBody = body[0].split(': ')
                this.percentage = splitBody[1]
                this.percentageName = splitBody[0]

                // select object with name
                const obj = this.content.data.filter((el) => el.label === splitBody[0])[0]
                this.amount = obj.ammout || null
                this.amountPerHour = obj.perHour || null
              })
            }
          }
        }
      })
    },
    setNewData () {
      if (this.pie) {
        this.percentage = null
        this.percentageName = null
        this.percentageTotal = null
        this.amount = null
        this.amountPerHour = null

        this.pie.data = this.datas
        this.pie.update()
      } else {
        this.initPie()
      }
    }
  }
}
