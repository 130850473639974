import store from '../store'

export const getStorage = () => {
  const storageKey = '_localstorage_'
  let retrieved
  try {
    if (window.localStorage) {
      window.localStorage.setItem(storageKey, storageKey)
      retrieved = window.localStorage.getItem(storageKey)
      window.localStorage.removeItem(storageKey)
    }
  } catch (e) {
    // ... ignore
  }

  if (storageKey === retrieved) {
    return window.localStorage
  }

  return {
    data: {},
    setItem: function (key, val) {
      this.data[key] = val
    },
    removeItem: function (key) {
      delete this.data[key]
    },
    getItem: function (key) {
      const value = this.data[key]
      return value === undefined ? null : value
    }
  }
}

function showPdf (blob, windowName = '') {
  const newBlob = typeof blob === 'string' ? blob : new Blob([blob], { type: 'application/pdf' })

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob)
    return
  }

  const data = window.URL.createObjectURL(newBlob)
  var link = document.createElement('a')
  link.href = data
  link.target = '_blank'
  if (windowName) {
    link.download = windowName
  }
  link.click()
  setTimeout(function () {
    window.URL.revokeObjectURL(data)
  }, 100)
}

function openUrl (url, windowName) {
  var link = document.createElement('a')
  link.href = url
  link.target = '_blank'
  if (windowName) {
    link.download = windowName
  }
  link.click()
}

function openData (data, windowName) {
  var pdfNewTab = window.open('')
  pdfNewTab.document.write(
    '<html><head><title>' + windowName + '</title><style>*{margin:0;padding:0;}</style></head><body><iframe title="' + windowName + '"  width="100%" height="100%" src="' + data + '"></iframe></body></html>'
  )
}

export const openPdf = (pdfId, windowName = '') => {
  // console.log('openPdf')
  // console.log('pdfId', pdfId)
  // console.log('windowName', windowName)
  if (pdfId.startsWith('http')) return openUrl(pdfId, windowName)
  if (pdfId.startsWith('data:')) return openData(pdfId, windowName)
  return fetch(`${process.env.VUE_APP_HEADLESS_URL}/api/pdfs/${pdfId}?view=pdf`, {
    headers: new Headers({
      Authorization: `Bearer ${store.state.auth.accessToken}`
    })
  }).then(r => r.blob()).then(blob => showPdf(blob, windowName))
}

export const MoneyFormat = (labelValue) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e+9

    ? Math.abs(Number(labelValue)) / 1.0e+9 + 'B'
    // Six Zeroes for Millions
    : Math.abs(Number(labelValue)) >= 1.0e+6

      ? Math.abs(Number(labelValue)) / 1.0e+6 + 'M'
      // Three Zeroes for Thousands
      : Math.abs(Number(labelValue)) >= 1.0e+3

        ? Math.abs(Number(labelValue)) / 1.0e+3 + 'K'

        : Math.abs(Number(labelValue))
}
