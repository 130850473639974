<template>
  <div class="switch-plane">
    <select v-model="currentAircraftID" class="switch-plane__select">
      <option v-for="el in listAircrafts" :key="el._id" :value="el._id">
        {{ el.registration }}
      </option>
    </select>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SwitchLang',
  data () {
    return {
      currentAircraftID: null
    }
  },
  watch: {
    currentAircraft: {
      handler (val) {
        this.currentAircraftID = val._id
      },
      immediate: true
    },
    currentAircraftID: {
      handler (val) {
        this.setCurrentAircraft(val)
      },
      immediate: true
    }
  },

  computed: {
    ...mapGetters('aircrafts', {
      listAircrafts: 'listAricrafts',
      currentAircraft: 'currentAircraft'
    })
  },
  methods: {
    ...mapActions('aircrafts', {
      setCurrentAircraft: 'setCurrentAircraft'
    })
  }
}
</script>

<style lang="less" scoped>
.switch-plane {
  margin-left: 24px;
  margin-top: auto;
  margin-bottom: auto;
  padding-right: 10px;

  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: transparent;

  cursor: pointer;

  &:hover {
    background-color: fade(#fff, 10%);
  }

  &__select {
    padding: 8px 0 8px 8px;

    background-color: transparent;
    color: #fff;
    text-transform: uppercase;
    border: none;
    outline: none;
    cursor: pointer;
  }
}
</style>
