import { createRouter, createWebHashHistory } from 'vue-router'

import store from '../store'

import Login from '../views/Login/index.vue'
import Aircraft from '../views/Aircraft/index.vue'
import Briefing from '../views/Briefing/index.vue'
import Financial from '../views/Financial/index.vue'
import ListFlights from '../views/ListFlights/index.vue'
import Reports from '../views/Reports/index.vue'
import Settings from '../views/Settings/index.vue'

const routes = [
  {
    path: '/',
    name: 'Aircraft',
    component: Aircraft,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/briefing',
    name: 'Briefing',
    component: Briefing,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/financial-details',
    name: 'Financial',
    component: Financial,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/flights-details',
    name: 'Flights Details',
    component: ListFlights,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior () {
    document.getElementById('app').scrollIntoView({ behavior: 'instant' })
  }
})

// Permissions handling
router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    store.commit('loading/setLoading', true)
    store.dispatch('auth/login')
      .then(() => {
        store.commit('loading/setLoading', false)
        next()
      })
      .catch(() => {
        store.commit('loading/setLoading', false)
        next({ name: 'Login', query: { redirect: to.path } })
      })
  } else {
    next()
  }
})

export default router
