import feathers from '../../feathers'

export default {
  namespaced: true,
  state: {
    fetched: false,
    missions: []
  },
  getters: {
    missions: (state, getters, rootState, rootGetters) => {
      if (!rootGetters['aircrafts/currentAircraft']) return {}

      const missions = (state.missions || []).filter(mission => mission.aircraft === rootGetters['aircrafts/currentAircraft']._id)

      return missions
    }
  },
  actions: {
    async find ({ state, commit }) {
      if (!state.fetched) {
        const missions = await feathers.client.service('api/my-plane/missions').find()
        commit('setMissions', missions)
      }
    }
  },
  mutations: {
    setMissions (state, missions) {
      state.fetched = true
      state.missions = missions
    }
  }
}
