<template>
  <ul class="financial-list">
    <li v-for="(statement, key) in statements" :key="key">
      <FinancialDetail :statement="statement" />
    </li>
    <li class="financial-list-total">
      <h3>{{ $t('financial-position-of-the-months') }}</h3>
      <span>{{ total }}</span>
    </li>
  </ul>
</template>

<script src="./script.js"></script>
<style src="./style.less" lang="less"></style>
