import componentMixin from '../../js/componentMixin'

export default {
  name: 'financial detail',
  props: {
    statement: {
      type: Object
    }
  },
  mixins: [componentMixin],
  data: () => {
    return {
      isOpen: false
    }
  },
  mounted () {
    this.checkSizes()
  },
  methods: {
    checkSizes () {
      this.size = 0
      this.$refs.subList.querySelectorAll('li').forEach(el => {
        this.size += el.getBoundingClientRect().height
      })
    },
    toggle () {
      if (this.isOpen) {
        this.$refs.subList.style.height = '0px'
        this.isOpen = false
      } else {
        this.$refs.subList.style.height = `${this.size}px`
        this.isOpen = true
      }
    }
  }
}
